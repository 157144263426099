import { useEffect, useState } from "react";
import {
  ConfigProvider,
  theme,
  Modal,
  Input,
  Button,
  Image,
  Statistic,
  Card,
  Table,
} from "antd";
import {
  ClockCircleTwoTone,
  StarTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import "./css/App.css";
import logo from "./assets/deyim-dedektifi-logo.png";
import deyimAnlamListesi from "./assets/deyimler";

const App = () => {
  const [isKullaniciAdiModalAcik, setIsKullaniciAdiModalAcik] = useState(true);
  const [kullaniciAdi, setKullaniciAdi] = useState("");
  const [kullaniciAdiGecerli, setKullaniciAdiGecerli] = useState(true);
  const [seciliDeyimler, setSeciliDeyimler] = useState([]);
  const [zaman, setZaman] = useState(0);
  const [toplamPuan, setToplamPuan] = useState(0);
  const [seciliDeyim, setSeciliDeyim] = useState();
  const [isOyunSonuModalAcik, setIsOyunSonuModalAcik] = useState(false);
  const [puanTablosu, setPuanTablosu] = useState([]);

  var eklenecekPuan = 0;

  useEffect(() => {
    setSeciliDeyimler(baslangicDeyimleriniAyarla(16));
  }, []);

  useEffect(() => {
    if (!isKullaniciAdiModalAcik) {
      //console.log("oyun başladı");
      rastgeleDeyimSec();
    }
  }, [isKullaniciAdiModalAcik]);

  const kullaniciAdiOnayla = () => {
    if (kullaniciAdi.length > 0) {
      setKullaniciAdiGecerli(true);
      setIsKullaniciAdiModalAcik(false);
      //console.log(kullaniciAdi);
    } else {
      setKullaniciAdiGecerli(false);
      return;
    }
  };

  const baslangicDeyimleriniAyarla = (sayi) => {
    const secilecekDeyimler = [];

    while (secilecekDeyimler.length < sayi && deyimAnlamListesi.length > 0) {
      const rastgeleIndex = Math.floor(
        Math.random() * deyimAnlamListesi.length
      );
      const deyimAnlam = deyimAnlamListesi[rastgeleIndex];

      if (!secilecekDeyimler.some((a) => a.deyim === deyimAnlam.deyim)) {
        secilecekDeyimler.push({ ...deyimAnlam, isSecili: 0 });
      }
    }

    return secilecekDeyimler;
  };

  const rastgeleDeyimSec = () => {
    let rastgeleIndex;
    let rastgeleDeyim;

    if (!isOyunSonuModalAcik) {
      if (seciliDeyimler.find((deyim) => deyim.isSecili === 0)) {
        do {
          rastgeleIndex = Math.floor(Math.random() * seciliDeyimler.length);
          rastgeleDeyim = seciliDeyimler[rastgeleIndex];
        } while (rastgeleDeyim.isSecili !== 0);

        //console.log(rastgeleDeyim);
        setSeciliDeyim(rastgeleDeyim);
        setZaman(30);
      } else {
        setZaman(0);
        setIsOyunSonuModalAcik(true);
      }
    }
  };

  const sureBitti = () => {
    seciliDeyim.isSecili = 2;
    setSeciliDeyimler([...seciliDeyimler]);
    rastgeleDeyimSec();
  };

  useEffect(() => {
    //console.log(seciliDeyimler);
  }, [seciliDeyimler]);

  const anlamiRenderEt = () => {
    if (Array.isArray(seciliDeyim.anlam)) {
      return seciliDeyim.anlam.map((item, index) => (
        <div key={index}>- {item}</div>
      ));
    } else {
      return <div>{seciliDeyim.anlam}</div>;
    }
  };

  useEffect(() => {
    //console.log(toplamPuan);
    if (isOyunSonuModalAcik) {
      fetch("https://submitscore-i3xtaxsa6q-ew.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: kullaniciAdi,
          score: toplamPuan,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setPuanTablosu(data.map((item) => ({ ...item, key: item.ranking })));
        });
    }
  }, [toplamPuan]);

  const deyimeTiklandi = (index) => {
    if (seciliDeyimler[index].isSecili === 0) {
      if (seciliDeyimler[index].deyim === seciliDeyim.deyim) {
        seciliDeyimler[index].isSecili = 1;
        setSeciliDeyimler([...seciliDeyimler]);
        setToplamPuan(toplamPuan + eklenecekPuan);
        rastgeleDeyimSec();
      } else {
        seciliDeyimler[index].isSecili = 2;
        setSeciliDeyimler([...seciliDeyimler]);
        rastgeleDeyimSec();
      }
    }
  };

  const oyunuYenidenBaslat = () => {
    setSeciliDeyimler(baslangicDeyimleriniAyarla(16));
    setToplamPuan(0);
    setIsOyunSonuModalAcik(false);
    setIsKullaniciAdiModalAcik(true);
    setPuanTablosu([]);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className="App">
        {!isKullaniciAdiModalAcik ? (
          <div className="centeredSquare">
            <div
              style={{
                height: "15%",
                display: "flex",
                backgroundColor: "#222",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <Image
                  src={logo}
                  height="100%"
                  preview={false}
                  className="logo"
                />
                <h1 style={{ color: "#fff", marginLeft: "20px" }}>
                  Deyim Dedektifi
                </h1>
              </div>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "50%",
                }}
              >
                <Card bordered={false} style={{ marginRight: "10px" }}>
                  <Statistic
                    title="Puan"
                    prefix={
                      <StarTwoTone
                        twoToneColor="#FEC20C"
                        style={{ marginRight: "5px" }}
                      />
                    }
                    value={toplamPuan}
                    valueStyle={{
                      color: "#FEC20C",
                      fontWeight: "bold",
                    }}
                    style={{
                      textAlign: "right",
                    }}
                  />
                </Card>
                <Card bordered={false}>
                  <Statistic.Countdown
                    title="Kalan Süre"
                    prefix={
                      <ClockCircleTwoTone
                        twoToneColor="#0492c2"
                        style={{ marginRight: "5px" }}
                      />
                    }
                    value={Date.now() + zaman * 1000}
                    format="s"
                    suffix=" sn"
                    valueStyle={{
                      color: "#0492c2",
                      fontWeight: "bold",
                    }}
                    style={{
                      textAlign: "right",
                    }}
                    onChange={(value) => {
                      eklenecekPuan = value;
                    }}
                    onFinish={sureBitti}
                  />
                </Card>
              </div>
            </div>
            <div
              style={{
                height: "70%",
                display: "flex",
              }}
            >
              <div className="grid-container">
                {seciliDeyimler.map((deyimAnlam, index) => (
                  <div
                    key={index}
                    className="grid-item"
                    style={{
                      backgroundColor:
                        deyimAnlam.isSecili === 1
                          ? "#3cb043"
                          : deyimAnlam.isSecili === 2
                          ? "#d0312d"
                          : "#ddd",
                    }}
                    onClick={() => deyimeTiklandi(index)}
                  >
                    {deyimAnlam.deyim}
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                height: "15%",
                display: "flex",
                backgroundColor: "#222",
              }}
            >
              {seciliDeyim && (
                <div
                  style={{
                    width: "100%",
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h2 className="anlam">{anlamiRenderEt()}</h2>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className="centeredSquare"
            style={{
              backgroundColor: "#222",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Image
                  src={logo}
                  height="300px"
                  width="300px"
                  preview={false}
                  className="logo"
                />
                <h1 style={{ color: "#fff" }}>
                  Deyim Dedektifi'ne Hoş Geldin.
                </h1>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3 style={{ color: "#fff" }}>
                  Başlamak için lütfen kullanıcı adını gir.
                </h3>
                <Input
                  id="kullaniciAdi"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  size="large"
                  placeholder="Kullanıcı Adı"
                  onChange={(e) => setKullaniciAdi(e.target.value)}
                  onPressEnter={kullaniciAdiOnayla}
                  maxLength={10}
                  status={kullaniciAdiGecerli ? "" : "error"}
                />
                <Button
                  type="primary"
                  size="large"
                  onClick={kullaniciAdiOnayla}
                  style={{ marginTop: "20px" }}
                >
                  Kaydet
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <Modal
        title={<h2>Lütfen kullanıcı adını gir.</h2>}
        centered
        open={false}
        onOk={kullaniciAdiOnayla}
        closeIcon={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={kullaniciAdiOnayla}
            size="large"
          >
            Kaydet
          </Button>,
        ]}
        keyboard={false}
        maskClosable={false}
        styles={{
          header: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          body: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          footer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <Input
          id="kullaniciAdi"
          prefix={<UserOutlined className="site-form-item-icon" />}
          size="large"
          placeholder="Kullanıcı Adı"
          onChange={(e) => setKullaniciAdi(e.target.value)}
          onPressEnter={kullaniciAdiOnayla}
          maxLength={10}
          status={kullaniciAdiGecerli ? "" : "error"}
        />
      </Modal> */}
      <Modal
        title={<h2>Skor Tablosu</h2>}
        centered
        open={isOyunSonuModalAcik}
        closeIcon={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={oyunuYenidenBaslat}
            size="large"
          >
            Yeniden Başlat
          </Button>,
        ]}
        keyboard={false}
        maskClosable={false}
        styles={{
          header: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          body: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          footer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <Table
          dataSource={puanTablosu}
          pagination={false}
          loading={puanTablosu.length === 0}
          columns={[
            {
              title: "Sıra",
              dataIndex: "ranking",
              key: "ranking",
            },
            {
              title: "Kullanıcı Adı",
              dataIndex: "username",
              key: "username",
            },
            {
              title: "Puan",
              dataIndex: "score",
              key: "score",
            },
          ]}
        />
      </Modal>
    </ConfigProvider>
  );
};

export default App;
